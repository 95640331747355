import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  textWrapper: {
    display:'flex',
    flexDirection: 'column',
    gap:'0.83333333rem',
    '@media (max-width: 767px)': {
      gap: '4.26666667rem',
      marginTop: '8.53333333rem',
    },
  },
    textIntr: {
        display:'flex',
        color: colors.secondaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '0.83333333rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '1.25rem',
        alignSelf: 'stretch',
       
        '@media (max-width: 767px)': {
          width: '100%',  
          alignSelf: 'stretch',
          fontSize: '4.26666667rem',
          lineHeight: '6.4rem',
          
        },
    },
});