import mp from '@/shared/ui/videos/sminex.mp4';



interface MockVideoDataType{
    url:string
}

export const mockVideoData:MockVideoDataType = {
    url:mp
}