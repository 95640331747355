import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    container: {
        width: '100%',
        margin: '0 auto',
        '@media (max-width: 767px)': {
          width: '100%',
          paddingBottom: '0px',
        },
    },
    firstSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '1.25rem',
        alignSelf: 'stretch',
        width: '100%',
        padding:'6.22rem 11%',
    
        '@media (max-width: 767px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8.53333333rem',
          alignSelf: 'stretch',
          borderTop: 'none',
          padding:'8.56rem 3.97rem',
        },

        
    }, 
    header: {
        width: '50%',
        color: colors.primaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '3.125rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '4.16666667rem',
        '@media (max-width: 767px)': {
          width: '100%',
          fontSize: '8rem',
          lineHeight: '10.6666667rem',
          alignSelf: 'stretch',
        },
      },
    description: {
        width: '30.43rem',
        display: 'flex',
        flexDirection: 'column',
        color: colors.secondaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '1.04166667rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '1.45833333rem',
        '@media (max-width: 767px)': {
          width: '100%',  
          alignSelf: 'stretch',
          fontSize: '4.26666667rem',
          lineHeight: '6.4rem',
        },
    },
    secondSectionContain: {
      margin: '0 auto',
        paddingLeft: 'calc(50vw - 50%)',
        paddingRight: 'calc(50vw - 50%)',
        backgroundColor: colors.brandGhostBackground,
    },
    secondSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '1.25rem',
        alignSelf: 'stretch',
        width: '100%',
        padding:'6.22rem 11%',
    
        '@media (max-width: 767px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8.53333333rem',
          alignSelf: 'stretch',
          padding:'8.56rem 3.97rem',
        },
    },
    text: {
      paddingBottom: '2.91666667rem',
      '@media (max-width: 767px)': {
        paddingBottom: '8.53333333rem',
      },
    },
    mail: {
      textDecoration: 'none',
      color: 'inherit',
      
      ':hover': {
          textDecoration: 'underline',
        },
      
    },
    phone: {
        textDecoration: 'none',
        color: 'inherit',
        paddingBottom: '1.25rem',
        ':hover': {
            textDecoration: 'underline',
        },
       '@media (max-width: 767px)': {
        paddingBottom: '4.26666667rem',
      },
    },
});