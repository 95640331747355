import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { Download } from '@/shared/ui/icons/Download';
import { FileItem } from '@/widgets/introduction-section/model/types';

interface CardWithLinkIntroductionProps extends FileItem {
    filesCount: number;
    onArchiveClick?: () => void;
}

export const CardWithLinkIntroduction: React.FC<CardWithLinkIntroductionProps> = ({ url, header, extension, date, size, filesCount, onArchiveClick}) => {
    

    return (
        <a href={url} download={header} target="_blank" {...stylex.props(styles.itemList)}>
            <span {...stylex.props(styles.round)}>
               <Download />
            </span>
            <div {...stylex.props(styles.point)}>
                <div   {...stylex.props(styles.documentPoint)}>{header}{date && <>&nbsp;(от {date})</>}{extension}</div>
                <div {...stylex.props(styles.documentInfo)}>
                    <div {...stylex.props(styles.documentSize)}>{size}</div>
                    {filesCount > 1 && (
                        <button 
                            onClick={(event) => {
                                event.preventDefault();
                                onArchiveClick?.()
                            }} 
                            {...stylex.props(styles.archiveButton)}
                        >
                            Архив
                        </button>
                    )}
                </div>
            </div>
        </a>
    );

    
};