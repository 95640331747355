import { SectionCooperationItem } from "./types";

export const mockSections: SectionCooperationItem[] = [
    {
        header: 'СОТРУДНИЧЕСТВО',
        description: 'Sminex заинтересован в новых партнерах для реализации совместных проектов. Если у вас есть идеи и предложения для сотрудничества, направляйте их на почту:',
        mail: 'sotrudnichestvo@sminex.com'
    },
    {
        header: 'ЛИНИЯ ДОВЕРИЯ',
        description: 'Sminex придерживается принципов честного и этичного ведения бизнеса, которые закреплены в корпоративном кодексе. Они распространяются на всех сотрудников и контрагентов. Вы можете сообщить о случаях нарушения этики, мошенничестве, хищениях или фактах коррупции со стороны сотрудников компании. Мы внимательно изучим каждый случай, идущий вразрез с корпоративными принципами.',
        phone: '+7 965 320 2640',
        mail: 'doverie@sminex.com'
    }
];