import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
}

export const Portal: React.FC<PortalProps> = ({ children }) => {
  // Создаем отдельный контейнер для портала
  const portal = document.getElementById('modal');

  if (!portal) {
    console.error('Portal root element not found');
    return null;
  }

  // Рендерим children в portalRoot с помощью createPortal
  return ReactDOM.createPortal(children, portal);
};
