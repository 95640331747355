import { FileItem } from "../model/types";

export const sortFilesByDate = (files: FileItem[]): FileItem[] => {
    return [...files].sort((a, b) => {
        if (!b.date) return 1;
        if (!a.date) return -1;
        
        const [dayA, monthA, yearA] = a.date.split('.').map(Number);
        const [dayB, monthB, yearB] = b.date.split('.').map(Number);
        
        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);
        
        return dateB.getTime() - dateA.getTime();
    });
};