export const VolumeOff = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" fill="none">
      <path
        d="M12.8827 5.5V1L8.23145 5.65121L1.5 5.65121V12.3481H5.54584M12.8827 10V17L9 13.1173M2 15.5L5.54584 12.3481M15.5 3.5L5.54584 12.3481"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
