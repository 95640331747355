import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IntrContactProps } from '../model/types';

const IntroductionContact: React.FC<IntrContactProps> = ({contactContent}) => {
    return (
        <>
        {contactContent.map((element, index) => ( <div key={index}>
                    <div {...stylex.props(styles.textIntr)}>{element.text}</div>
                    <div {...stylex.props(styles.textIntrContain)}>
                        {element.phone && (
                            <a href={`tel:${element.phone}`} {...stylex.props(styles.phone)}>
                                {element.phone}
                            </a>
                        )}
                        {element.mail && (
                            <a href={`mailto:${element.mail}`} {...stylex.props(styles.mail)}>
                                {element.mail}
                            </a>
                        )}
                    </div>
                </div>))}
        </>
    );
};

export default IntroductionContact;