import { IntroductionContact } from '@/enteties/introduction-contacts';
import { IntrContactItem } from '@/enteties/introduction-contacts/model/types';
import { IntroductionText } from '@/enteties/introduction-text';
import { IntrTextItem } from '@/enteties/introduction-text/model/types';
import { styles } from '@/pages/tenders-cooperation-page/ui/styles';
import * as stylex from '@stylexjs/stylex';


interface IntrTextListProps {
    mockText: IntrTextItem[];
    mockContacts: IntrContactItem[];
}
export const IntroductionTextList = ({mockText, mockContacts}: IntrTextListProps) => {
    return (
        <div {...stylex.props (styles.intrTextContainer)}>
            <IntroductionText textContent={mockText}/>
            <IntroductionContact contactContent={mockContacts}/>
        </div>
    );
};