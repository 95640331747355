export const Pause = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
      <path
        d="M8.5999 14.5999V7.3999M13.3999 14.5999V7.3999M4.9999 20.5999H16.9999C18.9881 20.5999 20.5999 18.9881 20.5999 16.9999V4.9999C20.5999 3.01168 18.9881 1.3999 16.9999 1.3999H4.9999C3.01168 1.3999 1.3999 3.01168 1.3999 4.9999V16.9999C1.3999 18.9881 3.01168 20.5999 4.9999 20.5999Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
