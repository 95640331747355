import { ControlProps } from '../controls/controls';
import styles from './progress-bar.module.css';

export const ProgressBar = ({ controlProps }: ControlProps) => {
  const { isLive, duration, currentTime, setCurrentTime, playerRef } = controlProps;

  const getProgressBarStyle = () => {
    const progress = (currentTime / duration) * 100;
    return {
      background: `linear-gradient(to right, rgb(110, 110, 246) ${progress}%, #ccc ${progress}%)`,
    };
  };

  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value);

    setCurrentTime(newTime);
    playerRef?.seekTo(newTime, 'seconds'); // Используем параметр 'seconds' для точности
  };
  return (
    <>
      {!isLive && (
        <input
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          onChange={handleSeekChange}
          className={styles.progressBar}
          style={getProgressBarStyle()}
        />
      )}

      <div className={styles.labelWrapper}>
        {isLive ? (
          <div className={styles.liveLabel}>Live</div>
        ) : (
          <div className={styles.timeLabel}>
            {Math.floor(currentTime / 60)}:{('0' + Math.floor(currentTime % 60)).slice(-2)} /{' '}
            {Math.floor(duration / 60)}:{('0' + Math.floor(duration % 60)).slice(-2)}
          </div>
        )}
      </div>
    </>
  );
};
