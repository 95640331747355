import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { MainLayout } from '@/app/main-layout';
import { VideoPlayer } from '@/features/video-player';
import { mockVideoData } from '@/features/video-player/model/mock-video-player';
import { BrokerPage } from '@/pages/broker-page';
import { ContactPage } from '@/pages/contact-page';
import { EmployerPage } from '@/pages/employer-page';
import { TendersCooperationPage } from '@/pages/tenders-cooperation-page';
import { InfoCenterNewsPage } from '@/pages/info-center-news-page';
import { InfoCenterAllNewsPage } from '@/pages/info-center-all-news-page';

import { routes } from '@/shared/constants/routes.ts';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainLayout />}>
        <Route path={routes.public.allNewsPage} element={<InfoCenterAllNewsPage />} />
        <Route path={routes.public.oneNewsPage} element={<InfoCenterNewsPage />} />
        <Route path={routes.public.bestEmployerPage} element={<EmployerPage />} />
        <Route path={routes.public.contactsPage} element={<ContactPage />} />
        <Route path={routes.public.brokerPage} element={<BrokerPage />} />
        <Route path={routes.public.videoPlayer} element={<VideoPlayer url={mockVideoData.url} />} />
        <Route path={routes.public.tendersCooperationPage} element={<TendersCooperationPage />} />
      </Route>
    </Route>
  )
);
