import mp from '@/shared/ui/videos/sminex.mp4';


export interface MockVideoType {
  autoPlay: boolean; // влияет на рендер кнопки плэй
  type: string;
  preview: string; // если есть грузи по урл или по id_resource
  previewLink: string; // урл || id_resource
  videoLink:string
}

export const mockVideo: MockVideoType[] = [
  {
    autoPlay: false,
    type: 'local',
    // previewType:'video'
    preview: '',
    previewLink: mp,
    videoLink:'https://player.vimeo.com/video/846968812?h=bc15b10dbb&amp;autoplay=1&amp;loop=1&amp;autopause=0',

  },
];
