export const VolumeUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" fill="none">
      <path
        d="M15.7692 5.34474C17.2486 7.16768 17.4575 9.59391 16.0503 11.7409M10.9598 1L6.07702 5.0698H1V10.9296L6.07702 10.9282L10.9598 15V1Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
