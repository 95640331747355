import { IntrSectionItem } from './types';

export const mockSections: IntrSectionItem[]  = [
    {
        type: 'Основные принципы работы Sminex с подрядчиками',
        files: [
            {
                header: 'Основные принципы работы Sminex с подрядчиками',
                extension: '.pptx',
                date: '04.07.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            }, 
        ] 
    },
    {
        type: 'Антикоррупционная политика Sminex',
        files: [
            {
                header: 'Антикоррупционная политика Sminex',
                extension: '.pdf',
                date: '',
                url: 'https://www.sminex.com/upload/iblock/bf1/1km2fyh07v384xnkt3b8mqhgu1i9s27f/codecs1.pdf',
                size: '304 кб',
            },
        ]
    },
    {
        type: 'Памятка по оформлению иностранных рабочих',
        files: [
            {
                header: 'Памятка по оформлению иностранных рабочих',
                extension: '.pdf',
                date: '',
                url: 'https://www.sminex.com/upload/iblock/4ed/e38jltrcfxzpb4qe68u6tsvzttm15yww/staff_rules.pdf',
                size: '291 кб',
            },
        ]
    },
    {
        type: 'Правила проведения тендера',
        files: [
            {
                header: 'Правила проведения тендера',
                extension: '.pdf',
                date: '',
                url: 'https://www.sminex.com/upload/iblock/5fb/6q01nit50679vpevy8o4ovmf6f92o0i1/tender_docs1.pdf',
                size: '440 кб',
            },
        ]
    },
    {
        type: 'Правила допуска на объект',
        files: [
            {
                header: 'Правила допуска на объект',
                extension: '.doc',
                date: '20.06.2024',
                url: 'https://www.sminex.com/upload/iblock/ce0/6xl4djpjd9w0hg8jrlt38htokrgofvn9/Pravila-dopuska-na-obekt-_ot-20.06.2024_.doc',
                size: '464 кб',
            },
            {
                header: 'Правила допуска на объект',
                extension: '.doc',
                date: '12.03.2024',
                url: 'https://www.sminex.com//upload/iblock/59b/a8m0lti4xdz09pwcb3uy3ep650peu4p6/Pravila-dopuska-na-obekt-_ot-12.03.2024_.doc',
                size: '402 кб',
            },
        ]
    },
    {
        type: 'Правила оформления документации',
        files: [

            {
                header: 'Правила оформления документации',
                extension: '.dox',
                date: '12.03.2024',
                url: 'https://www.sminex.com/upload/iblock/2b8/iei2j8024es4037ivmvj7gjux5bve0f8/Pravila-oformleniya-dokumentatsii-_ot-12.03.2024_.doc',
                size: '1613 кб',
            },
            {
                header: 'Правила оформления документации',
                extension: '.dox',
                date: '22.07.2024',
                url: 'https://www.sminex.com/upload/iblock/58e/dkxjiia1x3stxftz1ba4f8pj281xod10/Pravila-oformleniya-dokumentatsii-_ot-22.07.2024_.doc',
                size: '1613 кб',
            },
            {
                header: 'Правила оформления документации',
                extension: '.dox',
                date: '18.07.2024',
                url: 'https://www.sminex.com/upload/iblock/374/5dgyjkqollbkdoj1h7xvuzh98e1cv7ee/Pravila-oformleniya-dokumentatsii-_ot-18.07.2024_.doc',
                size: '1615 кб',
            },
        ]
    },
    {
        type: 'Стандарт культуры производства работ',
        files: [
            {
                header: 'Стандарт культуры производства работ',
                extension: '.pdf',
                date: '05.02.2024',
                url: 'https://www.sminex.com/upload/iblock/003/zfiwtd83z0lbehl7xk7jrzr0fh77seoi/Standart-kultury-proizvodstva-rabot-_ot-05.02.2024_.pdf',
                size: '2146 кб',
            },
            {
                header: 'Стандарт культуры производства работ',
                extension: '.pdf',
                date: '24.06.2024',
                url: 'https://www.sminex.com/upload/iblock/469/tayf6djgce8laywv51y7xu6yc1rn1ere/Standart-kultury-proizvodstva-rabot-_ot-24.06.2024_.pdf',
                size: '14825 кб',
            },
            {
                header: 'Стандарт культуры производства работ',
                extension: '.pdf',
                date: '31.05.2024',
                url: 'https://www.sminex.com/upload/iblock/d31/tc1odfp0nj5slytgm8l718o2xlkm2sl4/Standart-kultury-proizvodstva-rabot-_ot-31.05.2024_.pdf',
                size: '15167 кб',
            },
        ]
    },
    {
        type: 'Чек листы проверки ППР',
        files: [
            {
                header: 'Чек листы проверки ППР',
                extension: '.xlsx',
                date: '30.05.2024',
                url: 'https://www.sminex.com/upload/iblock/954/v2c628k2d157hqeybb39tf6jt3209sy8/CHek-listy-proverki-PPR-_ot-30.05.2024_.xlsx',
                size: '328 кб',
            },
        ]
        
    },
    {
        type: 'Правила оформления',
        files: [

            {
                header: 'Правила оформления',
                extension: '.dox',
                date: '12.03.2024',
                url: 'https://www.sminex.com/upload/iblock/2b8/iei2j8024es4037ivmvj7gjux5bve0f8/Pravila-oformleniya-dokumentatsii-_ot-12.03.2024_.doc',
                size: '1613 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.dox',
                date: '22.07.2024',
                url: 'https://www.sminex.com/upload/iblock/58e/dkxjiia1x3stxftz1ba4f8pj281xod10/Pravila-oformleniya-dokumentatsii-_ot-22.07.2024_.doc',
                size: '1613 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.dox',
                date: '18.07.2024',
                url: 'https://www.sminex.com/upload/iblock/374/5dgyjkqollbkdoj1h7xvuzh98e1cv7ee/Pravila-oformleniya-dokumentatsii-_ot-18.07.2024_.doc',
                size: '1615 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '04.07.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '05.07.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '08.08.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '03.04.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '11.07.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '12.08.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '22.04.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '18.07.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '24.08.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '22.03.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
            {
                header: 'Правила оформления',
                extension: '.pptx',
                date: '22.09.2024',
                url: 'https://www.sminex.com/upload/iblock/b45/lpjn6btle4ru2tcb8gnuk2tmr7z97n01/Osnovnye-printsipy-raboty-Sminex-s-Partnyerami_Kontragentami.pptx',
                size: '140 кб',
            },
        ]
    },
];