import React from 'react';
import { SectionCooperationProps } from '../model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

const SectionCooperation: React.FC<SectionCooperationProps> = ({sectionContent}) => {
    return (
        <>
            <div {...stylex.props(styles.container)}>
                <div {...stylex.props(styles.firstSection)}>
                    <div {...stylex.props(styles.header)}>{sectionContent[0]?.header}</div>
                    <div {...stylex.props(styles.description)}>
                        <div {...stylex.props(styles.text)}>{sectionContent[0]?.description}</div>
                        <a href={`mailto:${sectionContent[0]?.mail}`} {...stylex.props(styles.mail)}>{sectionContent[0]?.mail}</a>
                    </div>
                </div>
                <div {...stylex.props(styles.secondSectionContain)}>
                    <div {...stylex.props(styles.secondSection)}>
                        <div {...stylex.props(styles.header)}>{sectionContent[1]?.header}</div>
                        <div {...stylex.props(styles.description)}>
                            <div {...stylex.props(styles.text)}>{sectionContent[1]?.description}</div>
                            <a href={`tel:${sectionContent[1]?.phone}`} {...stylex.props(styles.phone)}>{sectionContent[1]?.phone}</a>
                            <a href={`mailto:${sectionContent[1]?.mail}`} {...stylex.props(styles.mail)}>{sectionContent[1]?.mail}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SectionCooperation;