import React, { useState, useRef, useEffect, ReactNode } from 'react';
import ReactPlayer from 'react-player';
import styles from './video-player.module.css';
import { Controls } from './controls/controls';
import { Pause } from '@/shared/ui/icons/Pause';
import { Play } from '@/shared/ui/icons/Play';

interface VideoPlayerProps {
  url: string;
  playButton?: ReactNode;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, playButton }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [volume, setVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const playerRef = useRef<ReactPlayer | null>(null);
  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [, setShowPlayPauseButton] = useState(false);
  const [duration, setDuration] = useState(0);
  const [_, setIsMouseMoving] = useState(true); // Состояние для отслеживания движения мыши
  const hideControlsTimeout = useRef<NodeJS.Timeout | null>(null); // Таймер для скрытия контролов

  // Определение, является ли устройство iPhone
  const isIPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    if (url.endsWith('.m3u8')) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  }, [url]);

  // Отслеживание изменений статуса полноэкранного режима
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    const player = playerRef.current?.getInternalPlayer();

    if (player && player.addEventListener) {
      player.addEventListener('webkitendfullscreen', () => {
        setIsFullscreen(false);
        setIsPlaying(false); // Меняем на Play после выхода из fullscreen на iPhone
      });
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      if (player && player.removeEventListener) {
        player.removeEventListener('webkitendfullscreen', () => {
          setIsFullscreen(false);
          setIsPlaying(false); // Меняем на Play после выхода из fullscreen на iPhone
        });
      }
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Обработчик окончания видео
  const handleEnded = () => {
    setIsPlaying(false); // Останавливаем видео
    setCurrentTime(0); // Сбрасываем текущее время на 0 (если хотите начать с начала)
  };
  const handleProgress = (state: { playedSeconds: number }) => {
    if (!isLive) {
      setCurrentTime(Math.round(state.playedSeconds));

      // Рассчитываем процент просмотра
      const progress = Math.round((state.playedSeconds / duration) * 100) + 1;
      // Обновляем стиль прогресс-бара
      const progressBar = playerContainerRef.current?.querySelector('.progressBar') as HTMLInputElement | null;
      if (progressBar) {
        progressBar.style.background = `linear-gradient(to right, rgb(110, 110, 246) ${progress}%, #ccc ${progress}%)`;
      }
    }
  };
  const handleDuration = (dur: number) => {
    if (!isLive) {
      setDuration(Math.round(dur));
    }
  };

  // Функция для показа контролов при движении мыши и их скрытия при отсутствии движения
  const handleMouseMove = () => {
    setIsMouseMoving(true);
    setShowControls(true);
    setShowPlayPauseButton(true);

    // Сбрасываем таймер, если он уже существует
    if (hideControlsTimeout.current) {
      clearTimeout(hideControlsTimeout.current);
    }

    // Устанавливаем таймер для скрытия контролов через 2 секунды
    hideControlsTimeout.current = setTimeout(() => {
      setIsMouseMoving(false);
      setShowControls(false);
      setShowPlayPauseButton(false);
    }, 3000);
  };

  const controlProps = {
    isIPhone,
    isPlaying,
    playButton,
    setIsPlaying,
    showControls,
    volume,
    currentTime,
    duration,
    playerRef: playerRef.current,
    setVolume,
    isLive,
    setCurrentTime,
    playerContainerRef: playerContainerRef.current,
    isFullscreen,
  };

  return (
    <div
      className={styles.playerWrapper}
      ref={playerContainerRef}
      onMouseMove={handleMouseMove} // Отслеживание движения мыши
    >
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        volume={volume}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleEnded}
        controls={isIPhone} // Включаем нативные контролы на iPhone
        width="100%"
        height="100%"
      />
      {!isPlaying && (
        <div className={`${styles.withoutOverlay}`}>
          {playButton || (
            <div className={styles.defaultPlayButton} onClick={() => setIsPlaying(true)}>
              <Play />
            </div>
          )}
        </div>
      )}

      {isPlaying && showControls && (
        <div className={`${styles.withoutOverlay}`}>
          {playButton || (
            <div className={styles.defaultPlayButton} onClick={() => setIsPlaying(false)}>
              <Pause />
            </div>
          )}
        </div>
      )}
      {!isIPhone && <Controls controlProps={controlProps} />}
    </div>
  );
};
