import { ICategoryItem } from '@/pages/info-center-all-news-page/ui/components/category-filter/model/types.ts';

import imgNews_1 from '@/shared/ui/assets/info-center-news-images/news-1.jpg';
import imgNews_2 from '@/shared/ui/assets/info-center-news-images/news-2.jpg';
import imgNews_3 from '@/shared/ui/assets/info-center-news-images/news-3.jpg';
import imgNews_4 from '@/shared/ui/assets/info-center-news-images/news-4.jpg';
import imgNews_5 from '@/shared/ui/assets/info-center-news-images/news-5.jpg';
import imgNews_6 from '@/shared/ui/assets/info-center-news-images/news-6.jpg';
import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';
import { INewsItem } from '@/pages/info-center-all-news-page/model';

export const DEFAULT_CATEGORY: ICategoryItem = { title: 'Все', id: null };
export const DEFAULT_CATEGORY_ID = null;

export const MOCK_CATEGORIES = [
  { title: 'Sminex', id: '1', isTop: true },
  { title: 'Малая Ордынка 19', id: '2' },
  { title: 'Ильинка 3/8', id: '3', isTop: true },
  { title: 'Городская усадьба', id: '4' },
  { title: 'Врубеля 4', id: '5' },
  { title: 'Фрунзенская набережная', id: '6' },
  { title: 'Достижение', id: '7' },
  { title: 'Тишинский бульвар', id: '8' },
  { title: 'Вестердам', id: '9' },
  { title: 'Лаврушинский', id: '10' },
  { title: 'Обыденский №1', id: '11' },
  { title: 'West Garden', id: '12' },
  { title: 'Чистые Пруды', id: '13', isTop: true },
  { title: 'LIFE TIME', id: '14', isTop: true },
  { title: 'TWIST', id: '15' },
];

export const MOCK_NEWS = [
  {
    id: '1',
    categoryId: '1',
    date: 1723612704,
    image: imgNews_1,
    title: 'Инвестор благоустроит 16 гектаров территорий в Москве',
    contentPreview:
      'В 2024 году городские власти благоустроят 60 парков, скверов и зелёных зон. Масштабные работы пройдут в парке Горького, «Сокольниках» и музее-заповеднике «Коломенское». В планах обустройство новых пешеходных и велосипедных маршрутов, всесезонных площадок для отдыха, детских игровых пространств и развитие пляжных зон у воды. ...',
    content:
      '<div class="news-detail-article-text mb_6"> <p>\n' +
      '\t В 2024 году городские власти благоустроят 60 парков, скверов и зелёных зон. Масштабные работы пройдут в парке Горького, «Сокольниках» и музее-заповеднике «Коломенское». В планах обустройство новых пешеходных и велосипедных маршрутов, всесезонных площадок для отдыха, детских игровых пространств и развитие пляжных зон у воды. Свой вклад в развитие комфортной городской среды также вносят частные инвесторы. Сегодня Sminex благоустраивает в Москве 16 гектаров территорий: дворов-парков в своих домах и городских общественных пространств.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t В настоящее время девелопер завершает создание пешеходного бульвара с фонтаном в доме «Достижение» и прогулочной зоны с прудом в «Садовых кварталах», которые будут доступны для посещения всем жителям Москвы в 2024 году.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Благоустройство в домах Sminex занимает до 70% территории. В городе-парке «Фрунзенская набережная» создан витражный парк с соснами площадью 3,1 гектара, здесь на каждую квартиру приходится 87 кв. метров благоустройства.<span style="color: red;"> </span>\n' +
      '</p>\n' +
      '<p>\n' +
      '\t В каждом доме девелопер создаёт живописные природные оазисы со взрослыми деревьями, декоративными кустарниками, цветниками и злаками. Озеленение подобрано таким образом, чтобы территория оставалась красивой в любое время года. Например, в доме «Лаврушинский» двор-парк площадью 1,4 гектара насчитывает свыше 3 тыс. растений: от благородного клёна и горной сосны до спиреи, ирги и бересклета.&nbsp;\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Важная составляющая благоустройства — водные объекты. Всего в домах Sminex их 28: это ручьи, каскады с гейзерами, сухие фонтаны и медиативные чаши. В квартале «Тишинский бульвар» создан пруд с «парящим» над водой настилом для йоги и ручьём для детских игр у воды.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Часть просторных зелёных дворов-парков занимают спортивные и детские площадки. Развивающие пространства учитывают современные знания в психологии и педагогике. В домах Sminex юные жители придумывают собственные сценарии игры и познают мир в комфортной и безопасной среде. Например, в «Обыденском № 1» детская площадка создана в концепции «перевёрнутого мира», в «Лаврушинском» игровая зона оформлена в морской стилистике, в её отделке в том числе используется галька с примесью ракушек.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Спортивные зоны в домах Sminex включают многофункциональные площадки для командных игр, пространства для воркаута и телесных практик.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t<i>«Мы поддерживаем городской тренд на развитие общественных пространств. В дополнение к благоустроенным московским паркам, которые дарят множество сценариев для досуга и отдыха, наши жители получают возможность наслаждаться красивым пейзажем, гулять с детьми и вести активный образ жизни в шаге от дома. Мы создаём комфортную среду как в своих домах, так и на прилегающих территориях»</i>, — рассказала вице-президент по согласованиям Sminex <b>Самира Левшина</b>.<br>\n' +
      '</p></div>',
  },
  {
    id: '2',
    categoryId: '1',
    date: 1723612704,
    image: imgNews_2,
    title: 'Клиенты Sminex получат больше миль «Аэрофлот Бонус» за квартиры',
    contentPreview:
      'Девелопер является партнёром программы «Аэрофлот Бонус». За покупку квартир в домах «Достижение» и West Garden, а также помещений в деловом центре Twist участники получают одну милю за каждые потраченные 100 рублей. А до 31 августа за приобретение недвижимости клиентам Sminex будут начислены дополнительные 10 000 миль. Мили ...',
    content:
      '<div class="news-detail-article-text mb_6"> <p>\n' +
      '\t Девелопер является партнёром программы «Аэрофлот Бонус». За покупку квартир в домах «Достижение» и West Garden, а также помещений в деловом центре Twist участники получают одну милю за каждые потраченные 100 рублей. А до 31 августа за приобретение недвижимости клиентам Sminex будут начислены дополнительные 10&nbsp;000 миль.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Мили начисляются за покупку жилой и коммерческой недвижимости. Например, за приобретение до 31 августа четырёхспальной квартиры за 78 510 000 рублей в выдающемся доме «Достижение», расположенном в окружении парков Останкина, будет начислено 795 100 миль «Аэрофлот Бонус» в течение 30 дней после покупки.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Мили можно использовать на приобретение авиабилетов, повышение класса обслуживания, товары и услуги партнёров программы «Аэрофлот Бонус». Получить подробную информацию о начислении миль можно у менеджера Sminex при выборе квартиры.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t<span>Условия начисления миль действительны за покупку недвижимости в домах «Достижение», West Garden и за покупку недвижимости в деловом центре Twist до 31 августа. Для начисления миль предъявите карту участника программы «Аэрофлот Бонуc» или сообщите номер карты не позднее даты подписания договора.</span>\n' +
      '</p></div>',
  },
  {
    id: '3',
    categoryId: '15',
    date: 1723094304,
    image: imgNews_3,
    title: '«Дзен». Богатства Минской улицы',
    contentPreview:
      'Возможно ли жить недалеко от цента Москвы и в окружении тысячи гектаров парков? С уверенностью отвечаем «да». Мы построили собрание клубных домов West Garden в районе престижной Минской улицы, чтобы ваша жизнь обрела идеальный баланс: зелёные массивы, собственная набережная, насыщенная инфраструктура и пульс мегаполиса. Но ...',
    content:
      '<div class="news-detail-article-text mb_6"> Возможно ли жить недалеко от цента Москвы и в окружении тысячи гектаров парков? С уверенностью отвечаем «да».&nbsp;<br>\n' +
      ' <br>\n' +
      ' Мы построили собрание клубных домов West Garden в районе престижной Минской улицы, чтобы ваша жизнь обрела идеальный баланс: зелёные массивы, собственная набережная, насыщенная инфраструктура и пульс мегаполиса. Но это только часть богатств локации.&nbsp;<br>\n' +
      ' <br>\n' +
      ' • Где искать сокровища царя Алексея Михайловича?<br>\n' +
      ' • Почему футболист Пеле и боксёр Майк Тайсон играли здесь в гольф?<br>\n' +
      ' • Как телепортироваться из лета в зиму всего за несколько минут?<br>\n' +
      ' <br>\n' +
      ' Открываем все секреты Минской улицы <b><a target="_blank" href="https://dzen.ru/a/ZoO0u9tpsSGmJ5Z9" class="js-parsed">в нашем Дзен-канале</a></b>.&nbsp;<br></div>',
  },
  {
    id: '4',
    categoryId: '4',
    date: 1723094304,
    image: imgNews_4,
    title: 'Определены самые востребованные пентхаусы в Москве',
    contentPreview:
      'В первом полугодии столичные девелоперы продали 20 пентхаусов. По данным компании Sminex, наиболее востребованными стали лоты площадью от 100 до 200 кв. м c двумя или тремя спальнями. Средняя стоимость покупки составила 258 млн рублей, средняя цена квадратного метра — 1,4 млн рублей.',
    content:
      '<div class="news-detail-article-text mb_6"> <p>\n' +
      '</p>\n' +
      '<p>\n' +
      '\t В первом полугодии столичные девелоперы продали 20 пентхаусов. По данным компании Sminex, наиболее востребованными стали лоты площадью от 100 до 200 кв. м c двумя или тремя спальнями. Средняя стоимость покупки составила 258 млн рублей, средняя цена квадратного метра — 1,4 млн рублей.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Всего на рынке недвижимости высших ценовых сегментов экспонировалось 212 пентхаусов: 113 — в премиальном, 99 — в элитном. Самым популярным районом стал Пресненский, где расположено 23%, или 49 лотов. На втором и третьем местах Замоскворечье и Хамовники. Здесь в продаже 31 и 27 пентхаусов — 15% и 13% общего предложения. Самым привлекательным районом для покупателей стала также Пресня, на которую приходится 30% купленных квартир — 6 из 20.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Больше всех пентхаусов строит и продаёт девелопер Sminex. На компанию приходится 33% общего предложения, или 71 лот, и почти половина проданных — 9 из 20. Три квартиры купили в элитном квартале «Тишинский бульвар», одну — в клубном доме «Обыденский № 1». В премиум-сегменте — четыре пентхауса в доме «Достижение» и ещё один — в Life Time, где продажи лотов этого формата стартовали в июне.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Наиболее востребованы пентхаусы площадью 100–200 кв. м — 11 из 20 проданных. При этом в экспонировании 67% лотов больше 200 кв. м, 13 пентхаусов — меньше 100 кв. м, самый компактный — 55 кв. м. Площадь трёх продаваемых пентхаусов превышает 1000 кв. м, самый большой — 1155 кв. м.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t В шести купленных лотах две спальни, ещё в шести — три. В экспонировании в 58 пентхаусах, или 27% предложения, пять и более спален, из них в трёх лотах 10 спален. В семи продаваемых пентхаусах одна спальня, в 49, или 23%, — четыре.\n' +
      '</p>\n' +
      '<p>\n' +
      '\t Важным критерием для покупки стало наличие в квартирах террасы и дровяного камина. В 14 проданных пентхаусах есть камин, в 13 из них — терраса. В элитном классе терраса есть во всех проданных лотах, камин — в четырёх. В экспонировании террасы в 75% пентхаусов, а камины — в 56%. 30%, или шесть проданных пентхаусов, двухэтажные. Балконы или лоджии есть в трёх лотах.\n' +
      '</p>\n' +
      ' <br>\n' +
      '<p>\n' +
      '</p></div>',
  },
  {
    id: '5',
    categoryId: '5',
    date: 1723007904,
    image: imgNews_5,
    title: 'Новости с наших строительных площадок за июль',
    contentPreview:
      'Вы знали, что в Москве 600 фонтанов? А недавно появилось ещё два. Мы завершили устройство фонтанов на территории наших домов «Достижение» и «Лаврушинский». Другими строительными итогами делимся в дайджесте за июль.',
    content:
      '<div class="news-detail-article-text mb_6"> <span>Вы знали, что в Москве 600 фонтанов? А недавно появилось ещё два. Мы завершили устройство фонтанов на территории наших домов «Достижение» и «Лаврушинский». Другими строительными итогами делимся в дайджесте за июль.<br>\n' +
      ' </span><br>\n' +
      ' <b><a target="_blank" href="https://lavrushinskiy.ru/dynamica-stroitelstva/" class="js-parsed">«Лаврушинский»</a></b>. Создаём комфортную среду во дворе-парке. Начали сооружать грот в форме волны на детской площадке. Облицевали верхние этажи всех корпусов.<br>\n' +
      ' <br>\n' +
      ' <b><a target="_blank" href="https://obydenskiy-1.ru/dynamics/" class="js-parsed">«Обыденский № 1»</a></b>. Продолжаем возводить подземные этажи комфортного паркинга. Приступили к устройству ливневой системы.<br>\n' +
      ' <br>\n' +
      ' <b><a target="_blank" href="https://chistye-prudy.ru/dynamica-stroitelstva/" class="js-parsed">«Чистые Пруды»</a></b>. Создали эталонный макет лифтового холла: итальянский белый и антрацитовый мрамор, панно из стемалита с орнаментом из латуни, авторские элементы декора. Укрепили исторический фасад.<br>\n' +
      ' <br>\n' +
      ' <b>«Тишинский бульвар»</b>.&nbsp;Завершаем устройство основания паркинга элитного квартала. Готовимся к монтажу распорной системы, чтобы укрепить котлован.<br>\n' +
      ' <br>\n' +
      ' <b><a target="_blank" href="https://dom-dostigenie.ru/dynamica-stroitelstva/" class="js-parsed">«Достижение»</a></b>. Заканчиваем благоустройство пешеходного бульвара вдоль дома. Запустили фонтан, высаживаем взрослые деревья. Монтируем декор на фасад дома.<br>\n' +
      ' <br>\n' +
      ' <b><a target="_blank" href="https://life-time.moscow/dynamics/" class="js-parsed">Life Time</a></b>.&nbsp;Завершили монолит корпусов B и C. Начали строить первый этаж школы на территории роскошного квартала.<br>\n' +
      ' <br>\n' +
      ' <b><a target="_blank" href="https://twist-office.ru/dynamica-stroitelstva/" class="js-parsed">Twist</a></b>.&nbsp;Приступили к строительству подземного этажа для ретейла. Демонтируем распорную систему, возводим стены и перекрытия.<br></div>',
  },
  {
    id: '6',
    categoryId: '7',
    date: 1722835104,
    image: imgNews_6,
    title: '«Москвич Mag». Ар-деко в Москве: стиль как достижение',
    contentPreview:
      'Среди многообразия архитектурных стилей Москвы особенно выделяется ар-деко. Здесь он обретает собственный характер, иные смыслы, но неизменно превращает повседневное пространство в произведение искусства.',
    content:
      '<div class="news-detail-article-text mb_6"> Среди многообразия архитектурных стилей Москвы особенно выделяется ар-деко. Здесь он обретает собственный характер, иные смыслы, но неизменно превращает повседневное пространство в произведение искусства.<br>\n' +
      ' <br>\n' +
      ' Редакция «Москвич Mag» выбрала шесть образцов ар-деко из прошлого и настоящего Москвы, где каждая деталь рассказывает историю своего времени.<br>\n' +
      ' <br>\n' +
      ' • Станция метро с колоннами как в римском Пантеоне<br>\n' +
      ' • Павильон ВДНХ в форме пятиконечной звезды<br>\n' +
      ' • «Храм литературы» из чёрного гранита, мрамора и бронзы<br>\n' +
      ' • Выдающийся дом со стрельчатыми арками, пилястрами и пиками на высоте 113 м<br>\n' +
      ' <br>\n' +
      ' Где искать эти ярчайшие примеры ар-деко? И есть ли будущее у этого стиля?&nbsp;<a href="https://moskvichmag.ru/gorod/epoha-dostizhenij-gde-iskat-yarkie-primery-stilya-ar-deko-v-moskve/" target="_blank" class="js-parsed"><b>Узнайте в статье</b></a>.</div>',
  },
];

export const infoCenterAllNewsPageConfig = ({
  filters,
  news,
}: {
  filters?: ICategoryItem[];
  news?: INewsItem[];
}): ComponentConfig<CommonProps>[] => [
  {
    component: 'CategoryFilter',
    props: {
      filters: filters,
    },
  },
  {
    component: 'ViewSwitchSection',
    props: {},
  },
  {
    component: 'NewsContainer',
    props: {
      news: news,
    },
  },
];
