import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IntrTextProps } from '../model/types';


const IntroductionText: React.FC<IntrTextProps> = ({textContent}) => {
    return (
        <>
        <div {...stylex.props(styles.textWrapper)}>

         {textContent.map((element, index) => (<div key={index} {...stylex.props(styles.textIntr)}>{element.text}</div>))}
        </div>
        </>
        
    );
};

export default IntroductionText;