export const FullScreen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
      <path
        d="M7.7999 1.4H3.53324C2.35503 1.4 1.3999 2.35513 1.3999 3.53333V7.8M7.7999 20.6H3.53324C2.35503 20.6 1.3999 19.6449 1.3999 18.4667V14.2M14.1999 1.4H18.4666C19.6448 1.4 20.5999 2.35513 20.5999 3.53333V7.8M20.5999 14.2V18.4667C20.5999 19.6449 19.6448 20.6 18.4666 20.6H14.1999"
        strokeLinecap="round"
      />
    </svg>
  );
};
