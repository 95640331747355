import { SectionCooperation } from '@/enteties/cooperation-section';
import { SectionCooperationItem } from '@/enteties/cooperation-section/model/types';


interface SectionListProps {
  mockSections: SectionCooperationItem[];
}

export const SectionListCooperation = ({ mockSections }: SectionListProps) => {
  return (
   
        <SectionCooperation sectionContent={mockSections}/>
     
  );
};
