import { IntrContactItem } from './types';


export const mockContacts: IntrContactItem[] = [
    {
        text: 'По закупкам подрядных работ и услуг',
        phone: '+7 495 152 43 02 доб. 2280',
        mail: 'tenders@sminex.com',
    },
    {
        text: 'По закупкам материалов и оборудования',
        phone: '+7 495 152 43 02 доб. 2281',
        mail: 'tenders@sminex.com',
    },
];
