import styles from './modal.module.css';
import { ReactNode, useEffect } from 'react';
import { Close } from '../../icons/Close';
import { Portal } from './portal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Блокируем скролл на фоне
    } else {
      document.body.style.overflow = ''; // Разблокируем скролл
    }

    return () => {
      document.body.style.overflow = ''; // Чистим эффект при размонтировании компонента
    };
  }, [isOpen]);
  return (
    <Portal>
      <div className={`${styles.modalOverlay} ${isOpen ? styles.open : ''}`}>
        <div className={`${styles.modalContent} ${isOpen ? styles.open : ''}`}>
          {children}
          <button className={`${styles.closeButton}`} onClick={onClose}>
            <Close />
          </button>
        </div>
      </div>
    </Portal>
  );
};
