import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    documentPoint: {
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    container: {
        display: 'flex',
        width: '38.36rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1.19791667rem',
        '@media (max-width: 767px)': {
            marginBottom: '8.53333333rem',
            width:'100%',
            gap: '6.13333333rem'
        },

    },
    headerText: {
        color: colors.primaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '1.875rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '2.08333333rem',
        alignSelf: 'stretch',
        marginBottom: '1.71875rem',
        '@media (max-width: 767px)': {
          width: '100%', 
          fontSize: '6.4rem',
          lineHeight: '8.53333333rem',
          marginBottom: '14,9333334rem',
        },
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100%',
        margin: 'auto',
        padding: '1.04166667rem',
        boxSizing: 'border-box',
        overflow: 'auto',
        '@media (max-width: 767px)': {
            justifyContent: 'start',
            padding: '5.33333335rem',
        },
    },
    contentWrapper: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.9375rem',
        '@media (max-width: 767px)': {
            margin: '0',
            gap: '4.26666667rem',
        },
    },

    modalHeader: {
        color: colors.primaryText,
        fontSize: '1.66666667rem',
        fontFamily: fonts.din2014Light,
        fontWeight: '500',
        marginBottom: '1.04166667rem',
        '@media (max-width: 767px)': {
            fontSize: '6.4rem',
            marginRight: '13.3333333rem',
            marginBottom: '8.53333333rem',
          
        },
    }
});