import * as stylex from '@stylexjs/stylex';
import { styles } from '../ui/styles';
import { mockSections } from '@/enteties/cooperation-section/model/mock-cooperation';
import { mockSections as mockSectionsIntr } from '@/widgets/introduction-section/model/mock-introduction';
import { mockText } from '@/enteties/introduction-text/model/mock-text';
import { mockContacts } from '@/enteties/introduction-contacts/model/mock-contacts';
import { ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';

export interface CommonProps {
  [key: string]: unknown;
}

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { ...stylex.props(styles.navContainer) },
    children: [
      {
        component: 'CardWithFloatBtn',
        props: {
          btnName: 'ПЕРЕЙТИ В ТЕНДЕРНУЮ СИСТЕМУ',
          redirectUrl: '/',
          firstSentence: 'Sminex проводит тендеры на выполнение работ,',
          secondSentence: 'поставку строительных материалов и оказание услуг.',
          title: 'ТЕНДЕРЫ',
        },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(styles.intrContainer) },
    children: [
      {
        component: 'SectionIntroduction',
        props: {
          sectionContent: mockSectionsIntr,
          text: 'ПРОСИМ ВАС ОЗНАКОМИТЬСЯ:',
        },
      },
      {
        component: 'IntroductionTextList',
        props: { mockText, mockContacts },
      },
    ],
  },
  {
    component: 'section',
    props: {},
    children: [
      {
        component: 'SectionListCooperation',
        props: { mockSections },
      },
    ],
  },
];
