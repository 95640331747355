export const FullScreenExit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
      <path
        d="M1.3999 14.4559H5.2664C6.44456 14.4559 7.39964 15.3728 7.39964 16.5039V20.6M7.3999 1.40002V5.4961C7.3999 6.6272 6.44482 7.54414 5.26666 7.54414H1.40017M20.5999 14.4559H16.7334C15.5552 14.4559 14.6002 15.3728 14.6002 16.5039V20.6M14.5999 1.40002V5.4961C14.5999 6.6272 15.555 7.54414 16.7331 7.54414H20.5996"
        strokeLinecap="round"
      />
    </svg>
  );
};
