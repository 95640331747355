import { routes } from '@/shared/constants/routes.ts';

export const headerTexts: Record<string, string> = {
  '/': 'Коротко о нас',
  [routes.public.contactsPage]: 'Контакты',
  [routes.public.bestEmployerPage]: 'Работодатель № 1',
  [routes.public.allNewsPage]: 'Информационный центр',
  [routes.public.oneNewsPage]: 'Информационный центр',
  [routes.public.brokerPage]: 'Кабинет брокера',
  [routes.public.tendersCooperationPage]: 'Тендеры',
};
