import { ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';
import styles from '../ui/BrokerPage.module.css';
import imageUrl from '@/shared/ui/assets/bg-images/City.png';

export interface CommonProps {
  [key: string]: unknown;
}

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { className: styles.navContainer },
    children: [
      {
        component: 'CardWithFloatBtn',
        props: {
          btnName: 'ПЕРЕЙТИ В КАБИНЕТ',
          redirectUrl: '/',
          firstSentence: 'Зарабатывайте с нами',
          title: 'КАБИНЕТ БРОКЕРА',
        },
      },
    ],
  },
  {
    component: 'StaticCard',
    props: {
      bgUrl: imageUrl,
    },
  },
];
