import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    textIntrContain: {
        display: 'flex',
        flexDirection: 'column',
        color: colors.primaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '1.04166667rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '1.45833333rem',
        alignSelf: 'stretch',
        '@media (max-width: 767px)': {
          width: '100%',  
          alignSelf: 'stretch',
          fontSize: '4.26666667rem',
          lineHeight: '6.4rem',
        },
    },
    textIntr: {
        color: colors.secondaryText,
        fontFamily: fonts.din2014Light,
        fontSize: '1.04166667rem',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '1.458333333rem',
        alignSelf: 'stretch',
        paddingBottom: '0.625rem',
        '@media (max-width: 767px)': {
          width: '100%',
          fontSize: '4.26666667rem',
          lineHeight: '6.4rem',
          paddingBottom: '3.2rem'
        },
    },
    phone: {
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    mail: {
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
            textDecoration: 'underline',
          },
      },
   
})