import React from 'react';
import { pageConfig } from '../model/mock-json';
import { renderComponent } from '@/shared/libs/dynamic-components-render.tsx';

export const BrokerPage = () => {
  return (
    <>
      {pageConfig().map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </>
  );
};
