import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    navContainer: {
        padding:'10.42rem 11.042rem 0',
        '@media (max-width: 767px)': {
            padding: '8.5rem 4rem',
            paddingTop: '29.8633333rem',
        },
    },
    intrContainer: {
        width: '78%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        padding:'6.22rem 0',
        borderTop: `0.01rem solid ${colors.tertiaryBorder}`,
        borderBottom: `0.01rem solid ${colors.tertiaryBorder}`,
        '@media (max-width: 767px)': {
          width: '100%',
          padding:'8.56rem 3.97rem',
          flexDirection: 'column',
          borderTop: 'none',
          borderBottom: 'none',
        },
    },
    intrTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '30.43rem',
        gap: '2.91666667rem',
        '@media (max-width: 767px)': {
          width: '100%',
          gap: '8.53333333rem',
        },
    },
    
});